// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import { useTranslation } from "react-i18next"; // Import useTranslation
import {
  getSetting,
  handleSetting,
  maintenanceMode,
  updateSetting,
} from "../../../redux/slice/settingSlice";
import ToggleSwitch from "../../extras/ToggleSwitch";


const Setting = (props) => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);
  

  const [privacyPolicyLink, setPrivacyPolicyLink] = useState();
  const [tnc, setTnc] = useState();
  const [tax, setTax] = useState();
  const [razorPayId, setRazorPayId] = useState("");
  const [razorSecretKey, setRazorSecretKey] = useState("");
  // box 5
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");

  const [currencyName, setCurrencyName] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [flutterWaveKey, setFlutterWaveKey] = useState();
  const { t} = useTranslation(); // Initialize useTranslation
  const [firebaseKey, setfirebaseKey] = useState();
  const [error, setError] = useState({
    firebaseKey: "",
    privacyPolicyLink: "",
    tnc: "",
    stripePublishableKey: "",
    stripeSecretKey: "",
    razorPayId: "",
    razorSecretKey: "",
    tax: "",
    currencyName: "",
    currencySymbol: "",
    flutterWaveKey: "",
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setCurrencyName(setting.currencyName);
      setCurrencySymbol(setting.currencySymbol);
      setStripePublishableKey(setting.stripePublishableKey);
      setStripeSecretKey(setting.stripeSecretKey);
      setRazorPayId(setting.razorPayId);
      setRazorSecretKey(setting.razorSecretKey);
      setTax(setting.tax);
      setPrivacyPolicyLink(setting?.privacyPolicyLink);
      setTnc(setting.tnc);
      setFlutterWaveKey(setting.flutterWaveKey);
      setfirebaseKey(JSON.stringify(setting.firebaseKey));
    }
  }, [setting]);

  const onsubmit = async (e) => {
    e.preventDefault();
    
    debugger;
    if (
      !privacyPolicyLink ||
      !tnc ||
      !stripePublishableKey ||
      !stripeSecretKey ||
      !razorPayId ||
      !razorSecretKey ||
      !tax ||
      !currencyName ||
      !currencySymbol ||
      !flutterWaveKey ||
      !firebaseKey
    ) {
      let error = {};
      if (!privacyPolicyLink)
        error.privacyPolicyLink = t('all.privacyPolicyLink_alert');
      if (!tnc) error.tnc = t('all.terms_req');
      if (!stripePublishableKey)
        error.stripePublishableKey = t('all.alert_stripe');
      if (!stripeSecretKey)
        error.stripeSecretKey = t('all.alert_stripe_secret');
      if (!razorPayId) error.razorPayId = t('all.alert_razor');
      if (!razorSecretKey)
        error.razorSecretKey = t('all.razor_req');
      if (!tax) error.tax = t('all.tax_req');
      if (!currencyName) error.currencyName = t('all.alert_currency');
      if (!currencySymbol) error.currencySymbol = t('all.alert_currency_symbol');
      if (!flutterWaveKey)
        error.flutterWaveKey = t('all.alert_flutter');
      if (!firebaseKey) error.firebaseKey = t('all.alert_firebase');
      return setError({ ...error });
    } else {
      const data = {
        privacyPolicyLink,
        tnc,
        stripePublishableKey,
        stripeSecretKey,
        razorPayId,
        razorSecretKey,
        tax,
        currencyName,
        currencySymbol,
        flutterWaveKey,
        firebaseKey,
      };
      const payload = { data: data, id: setting?._id };
      await dispatch(updateSetting(payload)).unwrap();
    }
  };

  const handleSettingSwitch = (id, type) => {
    
    const payload = {
      id,
      type,
    };
    dispatch(handleSetting(payload));
  };

  const handleAppActive = (id) => {
    
    dispatch(maintenanceMode(id));
  };

  return (
    <div className="mainSetting">
      <Title name={t('menu.setting')} />
      <div className="settingBox">
        <div className=" d-flex justify-content-end">
          <div className="  formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={t('all.submit')}
              style={{ backgroundColor: "#1ebc1e" }}
              onClick={onsubmit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader d-flex justify-content-between">
                <h4>{t('all.AppSetting')}</h4>
                <div className="inputData">
                  <label className="me-2">{t('all.Maintenance')}</label>
                  <ToggleSwitch
                    onClick={() => handleAppActive(setting?._id)}
                    value={setting?.maintenanceMode}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="privacyPolicyLink" className="ms-2 order-1">
                  {t('all.privacy_text')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="privacyPolicyLink"
                    value={privacyPolicyLink}
                    placeholder={t('all.privacy_placeholder')}
                    onChange={(e) => {
                      setPrivacyPolicyLink(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          privacyPolicyLink: t('all.privacyPolicyLink_alert'),
                        });
                      } else {
                        return setError({
                          ...error,
                          privacyPolicyLink: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.privacyPolicyLink}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="tnc" className="ms-2 order-1">
                  {t('all.terms')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="tnc"
                    value={tnc}
                    placeholder={t('all.tnc')}
                    onChange={(e) => {
                      setTnc(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          tnc: t('all.terms_req'),
                        });
                      } else {
                        return setError({
                          ...error,
                          tnc: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.tnc}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12 ">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="tax" className="ms-2 order-1">
                  {t('all.tax_per')}
                  </label>
                  <input
                    type="number"
                    className="rounded-2"
                    id="tax"
                    value={tax}
                    placeholder={t('all.tax_fill')}
                    onChange={(e) => {
                      setTax(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          tax: t('all.tax_req'),
                        });
                      } else {
                        return setError({
                          ...error,
                          tax: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.tax}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3 ">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>{t('all.razor_pay')}</h4>
              </div>
              <div className="col-12 ">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label className="my-3">{t('all.razor_active')}</label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="razorSecretKey"
                    value={razorSecretKey}
                    placeholder={t('all.razor_place')}
                    onChange={(e) => {
                      setRazorSecretKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorSecretKey: t('all.razor_req'),
                        });
                      } else {
                        return setError({
                          ...error,
                          razorSecretKey: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.razorSecretKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="razorPayId" className="ms-2 order-1">
                  {t('all.razor_id')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="razorPayId"
                    value={razorPayId}
                    placeholder={t('all.enter_razor')}
                    onChange={(e) => {
                      setRazorPayId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorPayId: t('all.alert_razor'),
                        });
                      } else {
                        return setError({
                          ...error,
                          razorPayId: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.razorPayId}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">{t('all.razorActive')}</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 1)}
                  value={setting?.isRazorPay}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3 ">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>{t('all.stripe')}</h4>
              </div>
              <div className="col-12 ">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label
                    htmlFor="stripePublishableKey"
                    className="ms-2 order-1"
                  >
                    {t('all.stripe_publish')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="stripePublishableKey"
                    value={stripePublishableKey}
                    placeholder={t('all.enter_stripe')}
                    onChange={(e) => {
                      setStripePublishableKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripePublishableKey: t('all.alert_stripe')
                        });
                      } else {
                        return setError({
                          ...error,
                          stripePublishableKey: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.stripePublishableKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="stripeSecretKey" className="ms-2 order-1">
                  {t('all.stripe_secret')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="stripeSecretKey"
                    value={stripeSecretKey}
                    placeholder={t('all.enter_stripe_secret')}
                    onChange={(e) => {
                      setStripeSecretKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripeSecretKey: t('all.alert_stripe_secret'),
                        });
                      } else {
                        return setError({
                          ...error,
                          stripeSecretKey: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.stripeSecretKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">{t('all.enable_stripe')}</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 2)}
                  value={setting?.isStripePay}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>{t('all.currency')}</h4>
              </div>

              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="currencyName" className="ms-2 order-1">
                  {t('all.name_currency')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="currencyName"
                    value={currencyName}
                    placeholder={t('all.enter_currency')}
                    onChange={(e) => {
                      setCurrencyName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          currencyName: t('all.alert_currency')
                        });
                      } else {
                        return setError({
                          ...error,
                          currencyName: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.currencyName}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="currencySymbol" className="ms-2 order-1">
                  {t('all.currency_symbol')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="currencySymbol"
                    value={currencySymbol}
                    placeholder={t('all.enter_currency_symbol')}
                    onChange={(e) => {
                      setCurrencySymbol(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          currencySymbol: t('all.alert_currency_symbol'),
                        });
                      } else {
                        return setError({
                          ...error,
                          currencySymbol: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.currencySymbol}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">{t('all.cash_After')}</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 5)}
                  value={setting?.cashAfterService}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>{t('all.firebase_setting')}</h4>
              </div>

              <div className="inputData text  flex-row justify-content-start text-start">
                <label className="float-left" htmlFor="firebaseKey">
                {t('all.privacy_firebase')}
                </label>
                <textarea
                  name="firebaseKey"
                  className=" mt-2"
                  id="firebaseKey"
                  rows={10}
                  value={firebaseKey}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    try {
                      const newData = JSON.parse(newValue);
                      setfirebaseKey(newValue);
                      setError("");
                    } catch (error) {
                      // Handle invalid JSON input
                      console.error(t('all.invalid_JSON'), error);
                      setfirebaseKey(newValue);
                      return setError({
                        ...error,
                        firebaseKey: t('all.invalid_JSON'),
                      });
                    }
                  }}
                ></textarea>

                {error.firebaseKey && (
                  <div className="pl-1 text-left">
                    <p className="errorMessage">{error.firebaseKey}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>{t('all.flutter_setting')}</h4>
              </div>

              <div className="col-12">
                <div className="inputData text  flex-row justify-content-start text-start">
                  <label htmlFor="flutterWaveKey" className="ms-2 order-1">
                    {t('all.flutter_key')}
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="flutterWaveKey"
                    value={flutterWaveKey}
                    placeholder={t('all.enter_flutter')}
                    onChange={(e) => {
                      setFlutterWaveKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          flutterWaveKey: t('all.alert_flutter'),
                        });
                      } else {
                        return setError({
                          ...error,
                          flutterWaveKey: "",
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="errorMessage text-start">
                      {error && error?.flutterWaveKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">{t('all.flutterwave_payment')}</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 4)}
                  value={setting?.isFlutterWave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Setting;
